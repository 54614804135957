import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiIconModule } from 'ui-icon';
import { InputComponent } from './input.component';

@NgModule({
	imports: [CommonModule, FormsModule, ReactiveFormsModule, UiIconModule],
	declarations: [InputComponent],
	exports: [InputComponent],
})
export class UiInputModule {}
