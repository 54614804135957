<div class="relative">
	<input
		class="w-full rounded-input px-3 py-1.5"
		[ngClass]="{
			'border-2 pr-8':
				((showInvalid && control?.invalid) ||
					(showValid && control?.valid)) &&
				isSubmitted,
			'border-feedback-error-500':
				showInvalid && control?.invalid && isSubmitted,
			'border-feedback-success':
				showValid && control?.valid && isSubmitted,
			'border border-grey-300': !(
				((showInvalid && control?.invalid) ||
					(showValid && control?.valid)) &&
				isSubmitted
			),
			'bg-grey-50': control?.disabled,
			'lowercase': isLowerCase,
		}"
		[type]="type"
		[min]="min"
		[max]="max"
		[id]="identifier"
		[attr.name]="control?.name"
		[disabled]="control?.disabled || false"
		[(ngModel)]="value"
		(ngModelChange)="updateValue(value)"
	/>
	<ui-icon
		*ngIf="showInvalid && isSubmitted && control?.invalid"
		name="error"
		class="absolute right-3 top-0 flex h-full items-center text-feedback-error-500"
		[ngClass]="{
			'opacity-disabled': control?.disabled
		}"
	></ui-icon>
	<ui-icon
		*ngIf="showValid && isSubmitted && control?.valid"
		name="check_circle"
		class="absolute right-3 top-0 flex h-full items-center text-feedback-success"
		[ngClass]="{
			'opacity-disabled': control?.disabled
		}"
	></ui-icon>
</div>
